<template>
  <div class="dealer">
    <!-- Add dialog -->
    <v-dialog v-model="addDialog" max-width="80%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Add {{ addCarModelFront.name }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-form style="width: 100%" ref="form" lazy-validation>
            <v-container style="padding: 30px">
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Brand*"
                    required
                    :rules="rules.brand"
                    v-model="addCarModelFront.carBrandId"
                    :items="carBrandList"
                    item-text="name"
                    item-value="id"
                    :placeholder="addCarModelFront.carBrandName"
                    prepend-icon="mdi-database-search"
                    @change="carBrandChange(addCarModelFront.carBrandId)"
                  >
                  </v-autocomplete>
                  <v-row>
                    <v-select
                      :items="carModelNameEntries"
                      label="Model Name*"
                      required
                      :rules="rules.model"
                      :placeholder="addCarModelFront.modelName"
                      v-model="addCarModelFront.modelName"
                    ></v-select>
                  </v-row>
                  <v-row>
                    <v-file-input
                      label="Thumbnail*"
                      required
                      :rules="rules.thumbnail"
                      v-model="addCarModelFront.thumbnailImgPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-row>
                  <v-row>
                    <v-text-field
                      type="number"
                      prefix="$"
                      v-model="addCarModelFront.priceFrom"
                      label="Price From*"
                      required
                      :rules="rules.price"
                      >{{ addCarModelFront.priceFrom }}</v-text-field
                    ></v-row
                  >
                  <v-row>
                    <v-text-field
                      v-model="addCarModelFront.bookTestDriveUrl"
                      label="Book Test Drive Url"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      v-model="addCarModelFront.youtubeUrl"
                      label="Youtube Url"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      v-model="addCarModelFront.description"
                      label="Description"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      v-model="addCarModelFront.carbuyerUrl"
                      label="Carbuyer Url"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-switch
                      label="Coe Inclusive"
                      v-model="addCarModelFront.coeInclusiveFlag"
                    ></v-switch>
                  </v-row>
                  <v-row>
                    <v-switch
                      label="Featured"
                      v-model="addCarModelFront.featuredFlag"
                    ></v-switch>
                  </v-row>

                  <v-row>
                    <v-text-field
                      type="number"
                      v-model="addCarModelFront.position"
                      label="Position"
                    ></v-text-field>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="doAddCarModelFront(addCarModelFront)"
                  >Submit</v-btn
                >
              </v-row>
            </v-container>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- edit dialog -->
    <v-dialog v-model="editDialog" max-width="90%">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editCarModelFront.name }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-form style="width: 100%" ref="editForm" lazy-validation>
            <div style="padding: 30px">
              <v-row>
                <v-col>
                  <v-autocomplete
                    label="Brand*"
                    required
                    :rules="rules.brand"
                    v-model="editCarModelFront.carBrandId"
                    :items="carBrandList"
                    item-text="name"
                    item-value="id"
                    :placeholder="editCarModelFront.carBrandName"
                    prepend-icon="mdi-database-search"
                    @change="carBrandChange(editCarModelFront.carBrandId)"
                  >
                  </v-autocomplete>
                  <v-row>
                    <v-select
                      :items="carModelNameEntries"
                      label="Model Name*"
                      required
                      :rules="rules.model"
                      :placeholder="editCarModelFront.modelName"
                      v-model="editCarModelFront.modelName"
                    ></v-select>
                  </v-row>
                  <v-row>
                    <v-file-input
                      label="Thumbnail*"
                      required
                      :rules="rules.thumbnail"
                      v-model="editCarModelFront.thumbnailImgPath"
                      clearable
                      accept="image/jpg,image/jpeg,image/png,image/gif"
                    ></v-file-input>
                  </v-row>
                  <v-row>
                    <v-text-field
                      type="number"
                      prefix="$"
                      v-model="editCarModelFront.priceFrom"
                      label="Price From*"
                      required
                      :rules="rules.price"
                      >{{ addCarModelFront.priceFrom }}</v-text-field
                    ></v-row
                  >

                  <v-row>
                    <v-text-field
                      v-model="editCarModelFront.bookTestDriveUrl"
                      label="Book Test Drive Url"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      v-model="editCarModelFront.youtubeUrl"
                      label="Youtube Url"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      v-model="editCarModelFront.description"
                      label="Description"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-text-field
                      v-model="editCarModelFront.carbuyerUrl"
                      label="Carbuyer Url"
                    ></v-text-field>
                  </v-row>

                  <v-row>
                    <v-switch
                      label="Coe Inclusive"
                      v-model="editCarModelFront.coeInclusiveFlag"
                    ></v-switch>
                  </v-row>
                  <v-row>
                    <v-switch
                      label="Featured"
                      v-model="editCarModelFront.featuredFlag"
                    ></v-switch>
                  </v-row>

                  <v-row>
                    <v-text-field
                      type="number"
                      v-model="editCarModelFront.position"
                      label="Position"
                    ></v-text-field>
                  </v-row>
                </v-col>

                <v-col>
                  <v-row> </v-row>
                  <v-row>
                    <v-col>
                      <div style="border: 1px dotted black; padding: 12px">
                        <CarFrontVariantGroupInput
                          :carModelFrontId="editCarModelFront.id"
                          :carBrandId="editCarModelFront.carBrandId"
                          :carModelName="editCarModelFront.modelName"
                          :carBrandName="editCarModelFront.carBrandName"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="doEditCarModelFront()"
                  >Submit</v-btn
                >
              </v-row>
            </div>
          </v-form>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="shortcutEditDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editCarModelFront.name }} Status
        </v-card-title>
        <v-card-text>
          <v-select
            v-model="editCarModelFront.status"
            :items="dealerStatusList"
            item-value="value"
            item-text="text"
            label="Dealer Status"
          >
          </v-select>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarModelFront()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editNameDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editCarModelFront.name }} Name
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="editCarModelFront.name" label="Dealer Name">{{
            editCarModelFront.name
          }}</v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarModelFront()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editPhotoDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editCarModelFront.name }} Thumbnail Picture
        </v-card-title>
        <v-card-text>
          <v-file-input
            label="Logo"
            v-model="editCarModelFront.thumbnailImgPath"
            clearable
            accept="image/jpg,image/jpeg,image/png,image/gif"
          ></v-file-input>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarModelFront()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editCrshortcutEditDialog" max-width="600px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editCarModelFront.name }} credit balance
        </v-card-title>
        <br />
        <v-card-text>
          <v-text-field
            type="number"
            prefix="$"
            v-model="editCarModelFront.creditBalance"
            label="Credit balance"
            >{{ editCarModelFront.creditBalance }}</v-text-field
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarModelFront()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDescriptionDialog" max-width="500px">
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>
          Edit {{ editCarModelFront.name }} Description
        </v-card-title>
        <v-card-text>
          <v-textarea
            v-model="editCarModelFront.description"
            :rows="2"
            auto-grow
            label="Description"
            >{{ editCarModelFront.description }}</v-textarea
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn text color="primary" @click="doEditCarModelFront()"
            >Submit</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-text-field
          @keyup.enter.native="searchDealer()"
          v-model="dealerSearch"
          label="ʕ•́ᴥ•̀ʔっ♡¯\_(ツ)_/¯"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <v-btn
          @click="searchDealer()"
          @keyup.enter.native="searchDealer()"
          large
          elevation
          color="primary"
        >
          Search
        </v-btn>
      </v-col>
      <v-btn fab color="red accent-2" @click="addDialog = true">
        <v-icon color="#fff">mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="dealerList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.thumbnailImgPath`]="{ item }">
        <v-btn class="mx-2" dark small @click="editPhotoButtonClick(item)">
          <img
            style="height: 50px"
            v-if="item.thumbnailImgPath != null"
            :src="item.thumbnailImgPath"
          />
        </v-btn>
      </template>
      <template v-slot:[`item.hide`]="{ item }">
        <v-switch
          v-model="item.hideFlag"
          @change="onToggleHideCarModelFront(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
          @click="editButtonClick(item)"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="red"
          @click="deleteButtonClick(item)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import uploadImage from "../../../services/image-uploader";
import CarFrontVariantGroupInput from "@/components/CarFrontVariantGroupInput";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";
import postAxios from "../../../services/axios-post";
import deleteAxios from "../../../services/axios-delete";

Vue.use(VueAxios, axios);

export default {
  components: {
    CarFrontVariantGroupInput,
  },
  data() {
    return {
      carModelNameEntries: [],
      carBrandSearchEntries: null,
      editDialog: false,
      selectedCarBrand: null,
      isLoading: false,
      dealerCarBrandsList: [],
      carBrandList: [],
      benched: 0,
      entries: [],
      search: null,
      dealerSearch: "",
      dealerStatus: "",
      imgFile: null,
      imgUrl: null,
      primaryListingBannerDisplay: null,
      secondaryListingBannerDisplay: null,
      tertiaryListingBannerDisplay: null,
      pageCount: 0,
      associatedCarBrandsDialog: false,
      shortcutEditDialog: false,
      editNameDialog: false,
      editPhotoDialog: false,
      editCrshortcutEditDialog: false,
      editDescriptionDialog: false,
      assignAccountManagerDialog: false,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Id", value: "id" },
        { text: "Brand", value: "carBrandName" },
        { text: "Model", value: "modelName" },
        { text: "Price From", value: "priceFrom" },
        { text: "Thumbnail", value: "thumbnailImgPath" },
        { text: "Hide", value: "hide" },
        { text: "Edit", value: "edit" },
        { text: "Delete", value: "delete" },
      ],
      dealerList: [],
      addDialog: false,
      addCarModelFront: {
        carBrandId: null,
        modelName: null,
        parentDealerId: null,
        coeInclusiveFlag: null,
        priceFrom: null,
        thumbnailImgPath: null,
        description: null,
      },
      editCarModelFront: {
        dealerId: null,
        name: null,
        mobilePhone: null,
        address: null,
        uenNum: null,
        status: null,
        creditBalance: 0,
        logoName: null,
        logoPath: null,
      },
      dealerStatusList: [
        { text: "PENDING APPROVAL", value: "PENDING_APPROVAL" },
        { text: "APPROVED", value: "APPROVED" },
        { text: "ACTIVE", value: "ACTIVE" },
        { text: "REJECTED", value: "REJECTED" },
      ],
      platformList: [],
      dealerTypeList: [],
      accountManagerList: [],
      assignDealerTypeDialog: false,
      assignDealerTypeData: {
        dealerTypeId: [],
        name: "",
        dealerId: "",
      },
      assignAccountManagerData: {
        accountManagerId: "",
        dealerId: "",
      },
      rules: {
        brand: [v => !!v],
        model: [v => !!v],
        thumbnail: [v => !!v],
        price: [v => !!v],
      },
    };
  },
  computed: {},
  watch: {
    editDialog(newVal) {
      if (!newVal) {
        this.resetEditItemInfo();
      }
    },
    search(val) {
      // Items have already been requested
      if (this.isLoading) return;

      this.isLoading = true;
      console.log("Loading here: " + val);
    },
  },
  mounted() {
    this.fetchCarModelFrontList();
    this.fetchCarBrand();
  },
  methods: {
    carBrandChange(carBrandId) {
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelModelName?carBrandId=${carBrandId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelNameEntries = res.data.data;
        }
      });
    },
    fetchCarBrand() {
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/baseDataSystem/carBrand?page=0&size=100&searchContent=`,
        {},
        ""
      ).then(res => {
        if (res.data) {
          self.carBrandList = res.data.data.content;
        }
      });
    },
    searchDealer() {
      this.fetchCarModelFrontList();
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarModelFrontList();
    },
    editButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDialog = true;
    },
    editStatusButtonClick(item) {
      this.setEditItemInfo(item);

      this.shortcutEditDialog = true;
    },
    editCreditButtonClick(item) {
      this.setEditItemInfo(item);

      this.editCrshortcutEditDialog = true;
    },
    editPhotoButtonClick(item) {
      this.setEditItemInfo(item);
      this.editPhotoDialog = true;
    },
    editNameButtonClick(item) {
      this.setEditItemInfo(item);
      this.editNameDialog = true;
    },
    editDescriptionButtonClick(item) {
      this.setEditItemInfo(item);
      this.editDescriptionDialog = true;
    },
    setEditItemInfo(item) {
      this.editCarModelFront.id = item.id;
      this.editCarModelFront.modelName = item.modelName;
      this.editCarModelFront.coeInclusiveFlag = item.coeInclusiveFlag;
      this.editCarModelFront.priceFrom = item.priceFrom;
      this.editCarModelFront.thumbnailImgPath = item.thumbnailImgPath;
      this.editCarModelFront.description = item.description;
      this.editCarModelFront.carBrandName = item.carBrandName;
      this.editCarModelFront.parentDealerName = item.parentDealerName;
      this.editCarModelFront.carBrandId = item.carBrandId;
      this.editCarModelFront.bookTestDriveUrl = item.bookTestDriveUrl;
      this.editCarModelFront.youtubeUrl = item.youtubeUrl;
      this.editCarModelFront.position = item.position;
      this.editCarModelFront.featuredFlag = item.featuredFlag;
      this.editCarModelFront.hideFlag = item.hideFlag;
      this.editCarModelFront.carbuyerUrl = item.carbuyerUrl;
    },
    resetEditItemInfo() {
      this.editCarModelFront.id = null;
      this.editCarModelFront.modelName = null;
      this.editCarModelFront.coeInclusiveFlag = null;
      this.editCarModelFront.priceFrom = null;
      this.editCarModelFront.thumbnailImgPath = null;
      this.editCarModelFront.description = null;
      this.editCarModelFront.carBrandName = null;
      this.editCarModelFront.parentDealerName = null;
      this.editCarModelFront.bookTestDriveUrl = null;
      this.editCarModelFront.position = null;
      this.editCarModelFront.featuredFlag = null;
      this.editCarModelFront.carbuyerUrl = null;
      this.imgFile = null;
    },
    resetAddItemInfo() {
      this.addCarModelFront.modelName = null;
      this.addCarModelFront.coeInclusiveFlag = null;
      this.addCarModelFront.priceFrom = null;
      this.addCarModelFront.thumbnailImgPath = null;
      this.addCarModelFront.description = null;
      this.addCarModelFront.carBrandName = null;
      this.addCarModelFront.parentDealerName = null;
      this.addCarModelFront.bookTestDriveUrl = null;
      this.addCarModelFront.position = null;
      this.addCarModelFront.featuredFlag = null;
    },
    assignDealerAssociatedTypesClick(item) {
      this.assignDealerTypeData.name = item.name;
      this.assignDealerTypeData.dealerId = item.id;
      this.assignDealerTypeDialog = true;
    },
    assignAccountManagerClick(item) {
      this.assignAccountManagerData.accountManagerId = item.accountManager;
      this.assignAccountManagerData.dealerId = item.id;
      this.assignAccountManagerDialog = true;
    },
    async doEditCarModelFront() {
      const self = this;
      try {
        if (this.$refs.editForm.validate()) {
          if (
            self.editCarModelFront.thumbnailImgPath &&
            self.editCarModelFront.thumbnailImgPath.name
          ) {
            const imgRes = await uploadImage(
              self.editCarModelFront.thumbnailImgPath
            );
            self.editCarModelFront.thumbnailImgPath = imgRes.data.data;
          }

          self.editCarModelFront.coeInclusiveFlag = self.editCarModelFront
            .coeInclusiveFlag
            ? 1
            : 0;

          self.editCarModelFront.featuredFlag = self.editCarModelFront
            .featuredFlag
            ? 1
            : 0;
          self.editCarModelFront.hideFlag = self.editCarModelFront.hideFlag
            ? 1
            : 0;
          putAxios(
            `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarModelFront`,
            self.editCarModelFront
          ).then(res => {
            if (res.data.httpStatus === "OK") {
              self.shortcutEditDialog = false;
              self.editNameDialog = false;
              self.editDescriptionDialog = false;
              self.editCrshortcutEditDialog = false;
              self.editPhotoDialog = false;
              self.editDialog = false;
              self.fetchCarModelFrontList();
              self.resetEditItemInfo();
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    fetchCarModelFrontList() {
      const self = this;
      const params = {
        page: self.pagination.page - 1,
        size: self.pagination.limit,
        searchContent: self.dealerSearch,
      };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carModelFront`,
        params
      ).then(res => {
        if (res.data) {
          self.dealerList = res.data.data.content;
          self.pagination.noOfPage = res.data.data.totalPages;
        }
      });
    },

    async doAddCarModelFront(addCarModelFront) {
      try {
        if (this.$refs.form.validate()) {
          const self = this;
          if (self.addCarModelFront.thumbnailImgPath) {
            const imgRes = await uploadImage(
              self.addCarModelFront.thumbnailImgPath
            );
            self.addCarModelFront.thumbnailImgPath = imgRes.data.data;
          }
          addCarModelFront.coeInclusiveFlag = addCarModelFront.coeInclusiveFlag
            ? 1
            : 0;
          addCarModelFront.featuredFlag = addCarModelFront.featuredFlag ? 1 : 0;
          addCarModelFront.hideFlag = 1;
          postAxios(
            `${process.env.VUE_APP_SERVER_URL}/carSystem/addCarModelFront`,
            addCarModelFront
          ).then(res => {
            if (res.data.httpStatus === "OK") {
              self.addDialog = false;
              self.fetchCarModelFrontList();
              self.resetAddItemInfo();
            }
          });
        }
      } catch (e) {
        console.error(e);
      }
    },
    deleteButtonClick(item) {
      const self = this;
      deleteAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/delCarModelFront?carModelFrontId=${item.id}`
      ).then(res => {
        if (res.data.httpStatus === "OK") {
          self.fetchCarModelFrontList();
        }
      });
    },

    onToggleHideCarModelFront(item) {
      const self = this;
      try {
        const data = {
          id: item.id,
          hideFlag: item.hideFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarModelFront`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchCarModelFrontList();
          }
        });
      } catch (e) {
        console.log(e);
      }
    },

    onToggleHide(item) {
      const self = this;
      try {
        const data = {
          dealerId: item.id,
          hideCarFlag: item.hideCarFlag ? 1 : 0,
        };
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/userSystem/updDealer`,
          data,
          self.token
        ).then(res => {
          if (res.data.httpStatus === "OK") {
            self.fetchCarModelFrontList();
          }
        });
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>
