<template>
  <div>
    <!-- alertMsg -->
    <v-alert :type="type" v-if="type">
      {{ alertMsg }}
    </v-alert>
    <div style="display: flex; justify-content: space-between">
      <!-- edit variants dialog box -->
      <v-dialog v-model="editDialog" max-width="600px">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Add Variant
          </v-card-title>
          <br />
          <v-card-text>
            <v-text-field
              v-model="showRoomUpdIn.name"
              label="Name"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.address"
              label="address"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.contactNumber"
              label="Contact Number"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.operationalHrs"
              label="Operational Hrs"
            ></v-text-field>
            <v-text-field
              v-model="showRoomUpdIn.facebookUrl"
              label="Facebook Url"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="doEditShowRoom(showRoomUpdIn)"
              >Edit</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- add variants dialog box -->
      <v-dialog v-model="dialog" max-width="800px">
        <v-card>
          <v-card-title class="headline grey lighten-2" primary-title>
            Add Variants
          </v-card-title>
          <br />
          <v-card-text>
            <!-- car brand, model name, submodel name selection-->
            <v-row>
              <v-col md="3" sm="12">
                <v-autocomplete
                  v-model="carBrandJSON"
                  :items="carBrandEntries"
                  :search-input.sync="carBrandSearch"
                  item-text="name"
                  item-value="id"
                  :loading="carBrandIsLoading"
                  :placeholder="carBrandNameDisplay"
                  prepend-icon="mdi-database-search"
                  return-object
                  @change="carBrandChange(carBrandJSON)"
                >
                </v-autocomplete>
              </v-col>
              <v-col md="3" sm="12">
                <v-select
                  :items="carModelNameEntries"
                  label="Model Name"
                  :placeholder="carModelNameDisplay"
                  v-model="carModelNameJSON"
                  @change="carModelNameChange(carBrandJSON, carModelNameJSON)"
                ></v-select>
              </v-col>
              <v-col md="3" sm="12">
                <v-select
                  :items="carModelSubNameEntries"
                  label="Submodel Name"
                  :placeholder="carModelSubNameDisplay"
                  v-model="carModelSubNameJSON"
                  @change="
                    carModelSubNameChange(
                      carBrandJSON,
                      carModelNameJSON,
                      carModelSubNameJSON
                    )
                  "
                ></v-select>
              </v-col>

              <v-col md="3" sm="12">
                <v-select
                  :items="carModelFinalEntries"
                  label="Model Year"
                  :placeholder="carModelFinalDisplay"
                  v-model="carModelFinalJSON"
                  :item-value="item => item.code"
                  :item-text="
                    item =>
                      item.modelYear +
                      ' - ' +
                      item.modelEndYear +
                      ' (' +
                      item.faceliftedYear +
                      ')'
                  "
                  @change="carModelFinalChange(carModelFinalJSON)"
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                  v-model="carModelFrontIn.dealerId"
                  :items="adDealers"
                  :search-input.sync="searchAdDealers"
                  item-value="id"
                  item-text="name"
                  :loading="isLoading"
                  placeholder="Dealer"
                >
                </v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  type="number"
                  prefix="$"
                  v-model="carModelFrontIn.salePrice"
                  label="Sale Price"
                  >{{ carModelFrontIn.salePrice }}</v-text-field
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-switch
                  label="Coe Inclusive"
                  v-model="carModelFrontIn.coeInclusiveFlag"
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="doAddVariant(carModelFrontIn)"
              >Add</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <h3>Variants</h3>
      <v-btn class="mx-2" fab small @click="dialog = true">
        <v-icon color="indigo">mdi-plus</v-icon>
      </v-btn>
    </div>
    <br />
    <div>
      <v-simple-table height="300px">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Brand</th>
              <th class="text-left">Model</th>
              <th class="text-left">SubVariant</th>
              <th class="text-left">Dealer</th>
              <th class="text-left">Price</th>
              <th class="text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in variantList" :key="index">
              <td>{{ item.carBrandName }}</td>
              <td>{{ item.modelName }}</td>
              <td>{{ item.subModelName }}</td>
              <td style="min-width: 220px;">
                <v-autocomplete
                  v-model="item.dealerId"
                  :items="adDealers"
                  item-value="id"
                  item-text="name"
                  :placeholder="item.dealerName"
                  @input.native="searchEditDealer($event)"
                >
                </v-autocomplete>
              </td>
              <td style="min-width: 90px;">
                <v-text-field v-model="item.salePrice">
                  {{ item.salePrice }}
                </v-text-field>
              </td>
              <td>
                <v-select
                  v-model="item.status"
                  :items="actionItems"
                  label="action"
                  persistent-hint
                  return-object
                  single-line
                  @change="onActionChange(item)"
                ></v-select>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import putAxios from "services/axios-put";
import postAxios from "services/axios-post";
import deleteAxios from "services/axios-delete";
import getAxios from "services/axios-get";

Vue.use(VueAxios, axios);

export default {
  props: {
    carModelFrontId: String,
    carBrandId: String,
    carBrandName: String,
    carModelName: String,
  },
  data() {
    return {
      item: {
        status: "",
      },
      type: null,
      alertMsg: null,
      variantList: [],
      actionItems: ["Save", "Remove"],
      showRooms: null,
      dialog: false,
      entries: [],
      isLoading: false,
      model: null,
      search: null,
      carModelFrontIn: {
        coeInclusiveFlag: null,
        salePrice: null,
        carModelCode: null,
        carModelFrontId: null,
      },
      editDialog: false,
      showRoomUpdIn: {
        id: null,
        name: null,
        address: null,
        contactNumber: null,
        operationalHrs: null,
        facebookUrl: null,
      },
      carModelJSON: null,

      carBrandNameDisplay: this.carBrandName,
      carBrandJSON: null,
      carBrandEntries: [],
      carBrandIsLoading: false,
      carBrandSearch: null,

      carModelNameDisplay: this.carModelName,
      carModelNameJSON: null,
      carModelNameEntries: [],

      carModelSubNameDisplay: "",
      carModelSubNameJSON: null,
      carModelSubNameEntries: [],

      carModelFinalDisplay: "",
      carModelFinalJSON: null,
      carModelFinalEntries: [],

      searchAdDealers: null,
      adDealers: [],
      assignedDealers: [],
    };
  },
  computed: {},
  mounted() {
    this.fetchVariantList(this.carModelFrontId);
    this.carBrandChange({ id: this.carBrandId });
    this.carModelNameChange({ id: this.carBrandId }, this.carModelName);
    this.carBrandJSON = { id: this.carBrandId };
    this.carModelNameJSON = this.carModelName;
  },
  watch: {
    carModelFrontId(newCarModelFrontId) {
      this.fetchVariantList(newCarModelFrontId);
    },
    carBrandId(newCarBrandId) {
      this.carBrandChange({ id: newCarBrandId });
      this.carBrandNameDisplay = this.carBrandName;
      this.carBrandJSON = { id: newCarBrandId };
    },
    carModelName(newCarModelName) {
      this.carModelNameChange({ id: this.carBrandId }, newCarModelName);
      this.carModelNameJSON = newCarModelName;
    },
    carBrandSearch(val) {
      if (val == "" || val == null) {
        this.resetCarModelSelection();
      }
      if (this.carBrandIsLoading) return;

      this.carBrandIsLoading = true;

      // Lazily load input items
      fetch(
        `https://hades.ucars.sg/relatedContent/carBrand?searchContent=${val}`
      )
        .then(res => res.json())
        .then(res => {
          this.carBrandEntries = res.data.content;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => (this.carBrandIsLoading = false));
    },
    searchAdDealers(val) {
      this.getAdDealers(val);
    },
  },
  methods: {
    searchEditDealer(e) {
      this.getAdDealers(e.target.value);
    },
    getAdDealers(val) {
      const search = { searchContent: val };
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/userSystem/dealer?isAuthorized=1`,
        search,
        {
          status: "APPROVED",
        },
        this.token
      )
        .then(res => {
          if (res.data) {
            this.adDealers =
              this.assignedDealers.length > 0
                ? this.assignedDealers.concat(res.data.data.content)
                : res.data.data.content;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    carBrandChange(carBrand) {
      const carBrandId = carBrand.id;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelModelName?carBrandId=${carBrandId}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelNameEntries = res.data.data;
        }
      });
    },
    carModelNameChange(carBrand, carModelName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getDistinctCarModelSubModelNameOption?adPiModel=AD&pastCarModel=CURRENT&${queryParams}`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelSubNameEntries = res.data.data;
        }
      });
    },

    carModelSubNameChange(carBrand, carModelName, carModelSubName) {
      const carBrandId = carBrand.id;
      let queryParams = `carBrandId=${carBrandId}&modelName=${encodeURIComponent(
        carModelName
      )}&submodelName=${encodeURIComponent(carModelSubName)}`;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/relatedDataSystem/getCarModel?${queryParams}&isAd=true`,
        self.token
      ).then(res => {
        if (res.data) {
          this.carModelFinalEntries = res.data.data;
        }
      });
    },

    carModelFinalChange(carModelCodeSelected) {
      this.carModelFrontIn.carModelCode = carModelCodeSelected;
    },
    test() {
      const xx = this.assignedSalespersonList.map(m => m.id);
      console.log(xx);
    },

    resetCarModelSelection() {
      this.editUsedCar.carModelCode = null;
      this.carModelNameJSON = "";
      this.carModelSubNameJSON = "";
      this.carModelFinalJSON = "";
      this.carModelNameEntries = [];
      this.carModelSubNameEntries = [];
      this.carModelFinalEntries = [];
    },
    onActionChange(item) {
      const status = item.status;
      if (status.includes("Remove")) {
        deleteAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/delCarModelFrontVariant?carModelFrontVariantId=${item.id}`
        )
          .then(res => {
            if (res) {
              this.showAlert("success", "Deleted successfully");
              this.fetchVariantList(this.carModelFrontId);
            }
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      } else if (status.includes("Save")) {
        putAxios(
          `${process.env.VUE_APP_SERVER_URL}/carSystem/updCarModelFrontVariant`,
          item
        )
          .then(res => {
            if (res) {
              this.showAlert("success", "Updated successfully");
              this.fetchVariantList(this.carModelFrontId);
            }
          })
          .catch(err => {
            console.log(err);
            this.showAlert("error", "failed..");
          });
      } else if (status.includes("Edit")) {
        this.showRoomUpdIn.id = item.id;
        this.showRoomUpdIn.name = item.name;
        this.showRoomUpdIn.address = item.address;
        this.showRoomUpdIn.contactNumber = item.contactNumber;
        this.showRoomUpdIn.operationalHrs = item.operationalHrs;
        this.showRoomUpdIn.facebookUrl = item.facebookUrl;
        this.editDialog = true;
      }
    },
    fetchVariantList(carModelFrontId) {
      this.assignedDealers = [];
      const self = this;
      getAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/carModelFrontVariant`,
        {
          carModelFrontId: carModelFrontId,
        }
      )
        .then(res => {
          self.variantList = res.data.data;
          for (let i = 0; i < self.variantList.length; i++) {
            const item = self.variantList[i];
            this.adDealers.push({ id: item.dealerId, name: item.dealerName });
            this.assignedDealers.push({
              id: item.dealerId,
              name: item.dealerName,
            });
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    resetCarModelFrontIn() {
      this.carModelFrontIn.coeInclusiveFlag = null;
      this.carModelFrontIn.salePrice = null;
      this.carModelFrontIn.carModelCode = null;
      this.carModelFrontIn.carModelFrontId = null;
      this.carModelFrontIn.dealerId = null;
      this.carBrandJSON = null;
      this.carModelNameJSON = null;
      this.carModelSubNameJSON = null;
      this.carModelFinalJSON = null;
    },
    doAddVariant(carModelFrontIn) {
      carModelFrontIn.carModelFrontId = this.carModelFrontId;
      carModelFrontIn.coeInclusiveFlag = carModelFrontIn.coeInclusiveFlag
        ? 1
        : 0;
      postAxios(
        `${process.env.VUE_APP_SERVER_URL}/carSystem/addCarModelFrontVariant`,
        carModelFrontIn
      )
        .then(res => {
          if (res) {
            this.showAlert("success", "Added successfully");
            this.fetchVariantList(this.carModelFrontId);
            this.dialog = !this.dialog;
            this.resetCarModelFrontIn();
          }
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    doEditShowRoom(showRoomUpdIn) {
      putAxios(
        `${process.env.VUE_APP_SERVER_URL}/outletSystem/updShowRoom`,
        showRoomUpdIn
      )
        .then(res => {
          if (res) {
            this.showAlert("success", "Edited successfully");
            this.fetchVariantList(this.dealerId);
            this.editDialog = !this.editDialog;
          }
        })
        .catch(err => {
          console.log(err);
          this.showAlert("error", "failed..");
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
  },
};
</script>
